<template>
  <BaseDialog :dialog="dialog" @close="close">
    <template v-slot:title>
      <img v-if="isShowIcon" class="" src="@/assets/images/dialog/question.svg" />
      <span class="title-text" tabindex="0"> {{ title || $t('register_data.title_confirmation') }}</span>
    </template>
    <template v-slot:content>
      <span v-html="message"></span>
    </template>
    <template v-slot:buttons>
      <CommonButton @action="close" :label="closeBtnLabel"></CommonButton>
      <CommonButton :buttonType="confirmBtnClass" @action="submit" :label="confirmBtnLabel"></CommonButton>
    </template>
  </BaseDialog>
</template>
<script>
import BaseDialog from '@/components/dialogs/base/DialogNotIncludeCloseIcon';
import CommonButton from '@/components/commonApp/ButtonCustom/NewButton.vue';
export default {
  components: { BaseDialog, CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: '',
      required: true,
    },
    confirmText: {
      type: String,
      default: 'OK'
    },
    confirmBtnClass: {
      type: String,
      default: 'warning-btn'
    },
    isShowIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    closeBtnLabel() {
      return this.$t('popup.button_cancel');
    },
    confirmBtnLabel() {
      return this.confirmText;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    }
  }
}
</script>
