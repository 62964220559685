<template>
  <v-dialog
    v-model="getDialog"
    @click:outside="handleClickOutSite"
    ref="dialog"
    max-width="464px"
  >
    <v-card>
      <v-card-title>
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text class="pt-4">
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="buttons"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      
    };
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
   close() {
      if(this.isLoading) {
        return
      }
      this.$emit('close');
    },
    handleClickOutSite() {
      this.close();
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/components/popup/index.scss';
::v-deep {
  .v-card__title {
    display: flex;
    gap: 12px;
    padding: 16px 32px !important;
    img {
      display: flex;
      width: 28px;
      height: 28px;
      padding: 0.5px;
      justify-content: center;
      align-items: center;
    }
    .title-text {
      color: $monoBlack;
      font-family: "Source Han Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
  }
  .v-card__text {
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .v-card__actions {
    display: flex;
    gap: 8px;
    width: 100%;
  }
}
</style>
