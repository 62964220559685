<template>
  <div>
    <v-dialog
      v-model="getDialog"
      ref="dialog"
      max-width="700px"
      min-height="626px"
      content-class="title-close log-history"
      scrollable
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t('a_linked_service.title_input_from_linked_service') }}</span>
          <div class="button-close" @click="close">
            <img src="@/assets/images/dialog/close.svg" class="button-close_close" />
            <img src="@/assets/images/dialog/close_active.svg" class="button-close_active" />
          </div>
        </v-card-title>
        <v-card-text>
          <div class="service-link-container">
            <div class="service-link-introduce" v-if="!isNextStepSearch">
              <div class="service-link-introduce__title">
                {{ $t('a_linked_service.label_select_linked_service_data') }}
              </div>
              <div class="service-link-introduce__content">
                <span>{{ $t('a_linked_service.label_package_CO2_calculation') }}</span>
                <p>{{ $t('a_linked_service.label_smartLCA_CO2') }}</p>
                <div class="service-link-introduce__redirect-step">
                  <div>{{ $t('a_linked_service.description_get_data_smartLCA_CO2') }}</div>
                  <common-button
                    class="btn-service-link btn-custom"
                    :label="$t('a_linked_service.button_obtain')"
                    type="colored"
                    @action="isNextStepSearch = true"
                    v-ripple="false"
                  />
                </div>
              </div>
            </div>
            <div class="service-link-content" v-if="isNextStepSearch">
              <div class="search-item">
                <div class="search-item-label">
                  {{
                    $t('a_linked_service.label_get_packaging_material_data_SmartLCA_CO2', {
                      serviceName: this.listServicesLinked[0].service_name,
                    })
                  }}
                </div>
              </div>
              <div class="service-input">
                <InputField
                  :label="$t('a_linked_service.placeholder_enter_packaging_code')"
                  class="input-custom color-textColor input-service-link"
                  :inputValue="inputValue"
                  @update:inputValue="handleUpdateTitle"
                  :isReadOnly="readOnly"
                />
                <common-button
                  class="btn-service-link"
                  :label="$t('a_linked_service.button_get_data')"
                  type="colored"
                  @action="handleFindServices"
                  v-ripple="false"
                  :disabled="validateInput || isSearching || readOnly"
                  :loading="isLoadingSubmit"
                />
              </div>
              <div v-if="isHasDataSearched" class="list-data">
                <div class="service-content">
                  <div>
                    {{ $t('a_linked_service.label_packaging_material_name') }}:
                    {{ serviceLinkData && serviceLinkData.product_name ? serviceLinkData.product_name : 'N/A' }}
                  </div>
                  <div>
                    {{ $t('a_linked_service.label_packaging_code') }}:
                    {{ serviceLinkData && serviceLinkData.code ? serviceLinkData.code : 'N/A' }}
                  </div>
                  <div>
                    {{ $t('a_linked_service.label_CO2_emission_amount_piece') }}:
                    {{ customServiceLinkData }}
                  </div>
                </div>
                <div class="wrap">
                  <div class="category-table service-link-table custom-table">
                    <data-table
                      :init-grid="initGrid"
                      :data-source="serviceLinkGrid"
                      :grid-columns="serviceLinkColumns"
                      :allowAddNew="false"
                      :isShowToolbar="false"
                      :rowFocus="1"
                      :showFocus="false"
                      :tableName="'searchExternalSource'"
                    />
                  </div>
                </div>
                <common-button
                  class="btn-service-link"
                  :label="$t('a_linked_service.button_import_data')"
                  type="colored"
                  @action="handleSubmit"
                  v-ripple="false"
                  :disabled="readOnly"
                />
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="!isNextStepSearch">
          <common-button class="left-button-popup" @action="close" :label="$t('a_linked_service.button_cancel')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notification-popup
      :dialog="dialogNotification"
      :message="$t('a_linked_service.description_failed_search_smartLCA_CO2')"
      @submit="dialogNotification = false"
      :confirmText="'OK'"
      typeColor="redColor"
    />
  </div>
</template>
<script>
import DataTable from '@/components/category/data-table';
import { mapActions } from 'vuex';
import InputField from '@/components/products/input/InputField.vue';
import { CollectionView } from '@mescius/wijmo';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';
import { getDetailServiceLink } from '@/api/serviceLink';
import { DB_TYPE } from '@/constants/dbType';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
import { math, formatValue } from '@/concerns/newRegisterData/wijmo.helper'
import { formatNumberByConditions, formatNumberRealNum   } from '@/utils/convertNumber';

export default {
  components: { DataTable, InputField, CommonButton, NotificationPopup },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Number,
      default: null,
    },
    codeProductValue: {
      type: Number | String,
      default: null,
    },
    listServicesLinked: {
      type: Array,
      default: null,
    },
    onlyViewedServicesLinked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      isLoading: false,
      inputValue: null,
      isHasDataSearched: false,
      isNextStepSearch: false,
      isSearching: false,
      serviceLinkData: {},
      flexGrid: null,
      serviceLinkGrid: null,
      serviceLinkColumns: [],
      dialogNotification: false,
      isLoadingSubmit: false
    };
  },
  mounted() {},
  created() {},
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    validateInput() {
      return !this.inputValue || this.inputValue.trim() === '';
    },
    readOnly() {
      return this.onlyViewedServicesLinked;
    },
    customServiceLinkData () {
      return this.serviceLinkData && this.serviceLinkData.co2_emission ? formatNumberRealNum(this.serviceLinkData.co2_emission.toString()) + 't-CO2' : 'N/A' 
    }
  },
  watch: {
    async codeProductValue(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.inputValue = this.codeProductValue;
        await this.handleFindServices();
      } else {
        this.isNextStepSearch = false;
        this.isSearching = false;
        this.inputValue = null;
        this.isHasDataSearched = null;
      }
    },
    onlyViewedServicesLinked() {
      if (!this.codeProductValue) {
        this.inputValue = null;
      }
      if (this.onlyViewedServicesLinked) {
        this.isNextStepSearch = false;
        this.isSearching = false;
        this.isHasDataSearched = null;
      }
    }
  },
  methods: {
    ...mapActions('commonApp', ['actionUpdateIsLoadingPopUp']),
    close() {
      if (this.isLoading) {
        return;
      }
      // this.isHasDataSearched = false;
      // this.isNextStepSearch = false;
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    handleUpdateTitle(value) {
      if (value !== this.inputValue) {
        // If update title for current -> set status searching to false
        this.isSearching = false;
        this.inputValue = value;
      }
    },
    async handleFindServices() {
      this.isLoadingSubmit = true;
      await this.fetchServiceLink();
      this.isNextStepSearch = true;
      this.isSearching = true;
    },
    handleSubmit() {
      const productCode = this.serviceLinkData?.code
      this.$emit('submit', { item: this.serviceLinkData }, this.selectedRow, productCode, DB_TYPE.SERVICE_LINK);
      this.$emit('close');
    },
    initGrid(grid) {
      this.flexGrid = grid;

      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.initGridColumn();
      }
      grid.formatItem.addHandler((s, e) => {});
    },
    initGridColumn() {
      return [
        {
          header: this.$t('a_linked_service.table_product_name'),
          binding: 'package_name',
          minWidth: getWidthByTextContent(this.$t('a_linked_service.table_product_name')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'left',
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('a_linked_service.table_product_quantity_number'),
          binding: 'package_value',
          minWidth: getWidthByTextContent(this.$t('a_linked_service.table_product_quantity_number')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('a_linked_service.table_original_location'),
          binding: 'package_value_source',
          minWidth: getWidthByTextContent(this.$t('a_linked_service.table_original_location'), 5),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('a_linked_service.table_product_quantity'),
          binding: 'co2_emission',
          minWidth: getWidthByTextContent(this.$t('a_linked_service.table_product_quantity')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row',
        },
      ];
    },
    initialView() {
      if (this.serviceLinkGrid) {
        this.data = this.serviceLinkGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
        this.serviceLinkGrid.currentItem = null;
      }
      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.initGridColumn();
      }
    },
    formatNumber(num) {
      return formatNumberByConditions(num, { isRealNumber: true })
    },
    async fetchServiceLink() {
      try {
        this.actionUpdateIsLoadingPopUp(true);
        const res = await getDetailServiceLink({
          service_link_id: this.listServicesLinked[0].id,
          product_code: this.inputValue,
        });
        this.serviceLinkData = res.data[0];
        if (!this.serviceLinkData) {
          this.actionUpdateIsLoadingPopUp(false);
          this.dialogNotification = true;
          this.isHasDataSearched = false;
          this.isLoadingSubmit = false;
          return;
        }
        this.isHasDataSearched = true; // has data searched
        const packages = [];
        this.serviceLinkData.packages.forEach((p) => {
          const totalEmissionValue = formatValue(
            math.evaluate(
              `${math.bignumber(formatValue(p.package_used_number))} * ${math.bignumber(
                formatValue(p.value_source),
              )}`,
            )
          );
          packages.push({
            ...p,
            package_name: p.package_name,
            package_value: this.formatNumber(p.package_used_number),
            package_value_source: this.formatNumber(p.value_source) + 't-CO2',
            co2_emission: this.formatNumber(totalEmissionValue) + 't-CO2'
          })
        });
        this.serviceLinkData.packages = packages;
        this.serviceLinkGrid = new CollectionView([...packages]);
        this.initialView();
        this.actionUpdateIsLoadingPopUp(false);
        this.isLoadingSubmit = false;
      } catch (error) {
        this.actionUpdateIsLoadingPopUp(false);
        this.isLoadingSubmit = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
.service-link-container,
.list-data {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.service-link-introduce {
  &__title {
    margin-bottom: 32px;
  }
  &__content {
    background: $monoWhite;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    gap: 8px;
    padding: 16px;
    font-size: 15px;
    line-height: 28px;
    color: $monoBlack;
    span {
      font-weight: 700;
      line-height: 24px;
    }
    p {
      font-weight: 400;
      margin: 0;
      line-height: 24px;
    }
  }
  &__redirect-step {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: $monoBlack;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }
}
.service-input {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.input-service-link {
  max-width: 200px;
}
.btn-service-link {
  max-width: max-content;
}
.service-content {
  color: $monoBlack;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.75px;
}
.search-item-label {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: left;
  color: $monoBlack;
}
.list-data {
  margin-top: 24px;
}
@media (min-width: 480px) {
  .wrap .service-link-table {
    max-width: calc(100% - 93px);
  }
}
@media (min-width: 1024px) {
  .wrap .service-link-table {
    max-width: calc(100% - 69px);
  }
}
</style>
<style lang="scss">
.btn-custom {
  &.common-btn.colored {
    background: $monoOffWhite !important;
    .v-btn__content {
      color: $monoBlack !important;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.6px;
    }
    &:hover {
      .v-btn__content {
        color: $monoWhite !important;
      }
    }
  }
}
</style>
