<template>
  <div>
    <DialogIncludeCloseIcon
      :dialogTitle="$t('idea_db_source.title_original_search')"
      :dialog="searchDialog"
      @close="close"
    >
      <div class="header-wrapper">
        <div class="header-form">
          <div class="header-form-field">
            <span>{{ $t("idea_db_source.title_select_original_type") }}</span>
            <InfiniteScroll
              :items="externalSourcePulldown"
              :model="pulldownModel"
              itemText="text"
              itemValue="value"
              @updateMasterDb="getPulldownModel"
            />
          </div>
          <div class="header-form-field search-input">
            <label class="header-form-field-label">{{ $t('basic_unit_library.label_search') }}</label>
            <InputField :getOriginalValue="true" v-model="searchText"/>
          </div>
          <div class="header-form-field condition-pulldown" :class="$i18n.locale">
            <span class="label-pulldown">{{ $t("basic_unit_library.label_pulldown_filter") }}</span>
            <div class="condition-search-btn">
              <v-select
                solo
                flat
                :items="conditionList"
                v-model="dataPulldown"
                item-text="text"
                item-value="text"
                class="select-type list-menu--select select-item select-condition"
                :menu-props="{ contentClass: 'select-menu export-select custom-dropdown' }"
              />
              <CommonButton
                :label="$t('register_data.button_search')"
                type="colored"
                @action="handleSeachFilter()"
                :isDisable="isDisableBtnSearch"
                v-ripple="false"
              />
            </div>
          </div>
          <div v-if="!isSupplier && !isCFP && isHasMonthYear && !isEmissionsView" class="header-form-field clear-filter-btn" :class="$i18n.locale">
            <CommonButton
              :label="$t('basic_unit_library.btn_clear_filter')"
              type="colored"
              @action="clearFilter()"
              v-ripple="false"
            />
          </div>
        </div>
        <div class="header-form">
          <div class="header-form-field page-size-list">
            <span class="label-pulldown">{{ $t('basic_unit_library.label_displayed_results') }}</span>
            <v-select
              solo
              flat
              :items="pagesizeList"
              v-model="currentPageSize"
              class="select-type list-menu--select select-item custom-pulldown-select select-pagesize"
              :menu-props="{ contentClass: 'select-menu export-select custom-dropdown' }"
              @change="handleSelectedPageSize"
            />
          </div>
        </div>
      </div>
      <div class="category-table main-table custom-table">
        <data-table
          :data-source="listBasicUnitGrid"
          :grid-columns="gridColumns"
          :init-grid="initGrid"
          :rowFocus="1"
          :allowAddNew="false"
          :isShowToolbar="false"
          :showFocus="true"
          :isEmptySource="isEmptySource"
          :isResizeCustome="false"
          :currentPage="currentPage"
          :currentPageSize="currentPageSize"
          :labelEmpty="$t('basic_unit_library.empty_source')"
          :tableName="'searchExternalSource'"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
          @initializedFilter="initializedFilter"
        />
      </div>
      <Paging
        :key="reRenderPaging"
        :totalDataList="totalDataList"
        :currentPageSize="currentPageSize"
        :isEmptySource="isEmptySource"
        @handleSelectedPage="handleSelectedPage"
      />
    </DialogIncludeCloseIcon>
    <ConfirmPopup
      confirmBtnClass="danger-btn"
      :title="$t('basic_unit_library.title_popup_comfirm')"
      :confirmText="$t('register_data.button_decision')"
      :dialog="confirmDialog"
      :message="messagePopupComfirm"
      @submit="handleConfirmSelectData"
      @close="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DialogIncludeCloseIcon from '@/components/dialogs/base/DialogIncludeCloseIcon';
import Paging from '@/components/commonApp/Paging';
import InfiniteScroll from '@/components/pulldown/InfiniteScroll';
import { getListIdeaDbSourceDetailsApi } from '@/api/ideaDbSource';
import DataTable from '@/components/category/data-table';

import { getWidthButtonByText } from '@/utils/multiLanguage';
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
import { CollectionView } from '@mescius/wijmo';
import * as wjGrid from '@mescius/wijmo.grid';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import {KEYS_CODE} from '@/constants/keyboard'
import ConfirmPopup from '@/components/dialogs/confirm-popup.vue';
import { getListBasicUnit } from '@/api/basic-unit.js';
import { CONDITIONLIST, DEFAULT_PAGING, PAGE_SIZE_LIST } from '@/constants/basic-unit.js';
import CommonButton from '@/components/utils/button.vue';
import InputField from '@/components/products/input/InputField.vue';
import { rangeDateFormat } from '@/utils/datetimeFormat';
import { formatNumberRealNum } from '@/utils/convertNumber';
import { formatNumberBySetting } from '@/concerns/newRegisterData/wijmo.helper'

export default {
  components: { DialogIncludeCloseIcon, Paging, InfiniteScroll, DataTable, ConfirmPopup, CommonButton, InputField },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    basicUnitListDefault: {
      type: Array,
      default: () => [],
    },
    objPatternData: {
      type: Object,
      default: () => {},
    },
    basicUnitFilterDataDefault: {
      type: Object,
      default: () => {},
    },
    isCFP: {
      type: Boolean,
      default: false
    },
    isSupplier: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPageSize: DEFAULT_PAGING.pageSize,
      pagesizeList: PAGE_SIZE_LIST,
      externalSourcePulldown: [],
      listBasicUnitGrid: null,
      flexGrid: null,
      selectedFilterColumn: null,
      isEmptySource: true,
      pulldownModel: '',
      confirmDialog: false,
      selectedBasicUnit: {},
      gridColumns: [],
      searchDialog: false,
      filter: null,
      listBasicUnit: [],
      conditionList: CONDITIONLIST,
      dataPulldown: 'AND',
      isDisableBtnSearch: false,
      payloadGetListBasicUnit: {
        condition: '',
        keyword: '',
      },
      optionsExternalSourcePulldown: [
        {
          text: this.$t('basic_unit_library.label_all'),
          value: '',
        },
        {
          text: this.$t('56_pattern.db_customize'),
          value: 'db-customize',
        },
        {
          text: this.$t('basic_unit_library.label_db_item'),
          value: 'db-item',
        },
      ],
      reRenderPaging: 0,
      currentPage: 1,
      searchText: '',
    };
  },
  async mounted() {
    this.searchDialog = this.dialog;
    this.listBasicUnit = this.basicUnitListDefault.map((item) => {
      return {
        ...item,
        value_source: formatNumberRealNum(item?.value_source),
    }});
    this.actionUpdateIsLoadingPopUp(true);
    this.gridColumns = this.getGridColumns();
    await Promise.all([this.fetchIdeaDBsourcesPullDown()]);
    this.addDbCustomizeAndDbItemToPulldown(this.externalSourcePulldown);
    this.defineTable();
    this.actionUpdateIsLoadingPopUp(false);
  },
  computed: {
    totalDataList() {
      return this.listBasicUnitGrid?.totalItemCount;
    },
    messagePopupComfirm(){
      return this.$i18n.locale === 'en' ? `${this.$t('basic_unit_library.basic_unit_name')} ${this.selectedBasicUnit?.name}` : `${this.$t('basic_unit_library.basic_unit_name')}${this.selectedBasicUnit?.name}`
    },
    isHasMonthYear() {
      return !!(this.objPatternData.month && this.objPatternData.year)
    },
    isEmissionsView() {
      return this.$route.path.includes('emissions/view')
    }
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingPopUp']),
    close() {
      this.$emit('close');
    },
    getGridColumns() {
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'select_source',
          allowSorting: false,
          align: 'center',
          minWidth: getWidthButtonByText(this.$t('b_register_product.button_choice') , { fontSize: '13px', fontWeight: 500, letterSpacing: '0.03em', lineHeight: 20 }),
          maxWidth: getWidthButtonByText(this.$t('b_register_product.button_choice') , { fontSize: '13px', fontWeight: 500, letterSpacing: '0.03em', lineHeight: 20 }),
          wordWrap: true,
          cssClass: "btn-detail-product",
          cssClassAll: 'btn-detail hide-filter product-detail select-source',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_register_product.button_choice'),
            click: (event, context) => this.handleIdClick(context),
          }),
        },
        {
          header: this.$t('basic_unit_library.basic_unit_name'),
          binding: 'name_basic',
          minWidth: 500,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('register_data.table_value'),
          binding: 'value_source',
          minWidth: 70,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          align: 'right',
        },
        {
          header: this.$t('register_data.table_unit'),
          binding: 'unit_source',
          minWidth: 70,
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: '',
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('register_data.table_wsu_source'),
          binding: 'source',
          minWidth: 360,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t("basic_unit_library.label_expired_time"),
          binding: 'expired_time',
          width: '*',
          minWidth: getWidthByTextContent(this.$t("basic_unit_library.label_expired_time")),
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
      ]
    },
    async handleSeachFilter() {
      this.actionUpdateIsLoadingPopUp(true);
      const value = this.searchText;
      try {
        const condition = this.dataPulldown;
        this.isDisableBtnSearch = true;
        this.payloadGetListBasicUnit = {
          ...this.payloadGetListBasicUnit,
          condition,
          keyword: value || '',
        };
        await this.handleGetListBasicUnit(this.payloadGetListBasicUnit);
        if (this.isFilterActive().isFilterActive) {
          if (this.isFilterActive().isFilterHasSearchText) {
            this.filterAfterSearch()
          } else {
            this.handleDefaultFilterData()
          }
        }
        this.isDisableBtnSearch = false;
        this.handleSelectedPage(1);
        this.reRenderPaging++; // force re-render paging
        this.actionUpdateIsLoadingPopUp(false);
      } catch (err) {
        console.warn(err);
        this.isDisableBtnSearch = false;
        this.actionUpdateIsLoadingPopUp(false);
      }
    },
    async handleGetListBasicUnit(params) {
      // call API
      try {
        this.listBasicUnit = [];
        const response = await getListBasicUnit({
          ...params,
          row_num_pattern: this.objPatternData?.row_num_pattern
        });
        this.listBasicUnit = response?.map((item) => {
          return {
            ...item,
            value_source:formatNumberRealNum(item.value_source),
            source: item?.type_db == 2 ? item?.name_basic : item?.source,
            expired_time: rangeDateFormat({
              year: item?.year_from,
              month: item?.month_from,
            }) + ' - ' + rangeDateFormat({
              year: item?.year_end,
              month: item?.month_end,
            })
          };
        });
        this.isEmptySource = this.listBasicUnit.length <= 0;
        this.handleUpdateCollectionData(this.listBasicUnit);
        // lazy loading data
        // this.handleLoadMoreData({payload, totalItem: response.total});
      } catch (error) {
        console.warn(error);
      }
    },
    async handleLoadMoreData(params) {
      const { payload, totalItem } = params;
      if (payload.perPage >= totalItem) return;
      try {
        const res = await getListBasicUnit({
          ...payload,
          per_page: totalItem
        });
        res.data.splice(0, payload.perPage) // remove exists items
        res.data.forEach(item => {
          this.listBasicUnitGrid.sourceCollection.push({
            ...item,
          })
        })
        this.listBasicUnitGrid.refresh();
      } catch (error) {
        console.warn(error);
      } 
    },
    defineTable() {
      this.initialView();
      this.isEmptySource = this.listBasicUnit.length <= 0;
    },
    addDbCustomizeAndDbItemToPulldown(externalSourcePulldown) {
      this.externalSourcePulldown = [...this.optionsExternalSourcePulldown, ...externalSourcePulldown];
    },
    async fetchIdeaDBsourcesPullDown() {
      try {
        const res = await getListIdeaDbSourceDetailsApi();
        this.externalSourcePulldown = res.data.map((source) => ({
          ...source,
          value: source.id.toString(),
          text: source.db_name,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDetailsIdeaSources(value) {
      this.actionUpdateIsLoadingPopUp(true);
      try {
        const payload = this.preparePayloadDbType(value);
        await this.handleGetListBasicUnit(payload);

        this.handleUpdateCollectionData(this.listBasicUnit);
        this.handleSelectedPage(1);
        this.reRenderPaging++;
        this.handleDefaultFilterData();
        this.actionUpdateIsLoadingPopUp(false);
      } catch (error) {
        console.error(error);
        this.actionUpdateIsLoadingPopUp(false);
      }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    initGrid(grid) {
      this.flexGrid = grid;
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
    },
    handleUpdateCollectionData(value) {
      this.listBasicUnitGrid.sourceCollection = [];
      this.listBasicUnitGrid.sourceCollection = [...value];
    },
    initialView() {
      this.listBasicUnitGrid = new CollectionView([...this.listBasicUnit], {
        pageSize: DEFAULT_PAGING.pageSize,
        sortComparer: (a, b) => {
          if (this.selectedFilterColumn !== 'category_name') return null;
          const valueA = a?.split('カテゴリ')[1]?.trim();
          const valueB = b?.split('カテゴリ')[1]?.trim();
          if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
            const numA = Number(formatValue(valueA));
            const numB = Number(formatValue(valueB));
            return numA - numB;
          }
          return null;
        },
      });
      this.listBasicUnitGrid.currentItem = null;
      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.gridColumns;
        setMinMaxSizeColumns(this.flexGrid, this.listBasicUnit);
        this.$nextTick(() => {
          this.handleTableFilter();
          this.handleDefaultFilterData();
        })
      }
    },
    initializedFilter(filter) {
      this.filter = filter;
    },
    handleIdClick(context) {
      this.selectedBasicUnit = {
        ...context.item,
        value_source: formatNumberBySetting(context.item.value_source),
        name: context?.item?.name_basic,
      }
      this.confirmDialog = true;
      this.searchDialog = false;
    },
    handleSelectedPage(currentPage) {
      this.currentPage = currentPage;
      this.listBasicUnitGrid.moveToPage(currentPage - 1);
      setMinMaxSizeColumns(this.flexGrid, this.listBasicUnitGrid.sourceCollection);
    },
    handleSelectedPageSize(pageSize) {
      this.listBasicUnitGrid.pageSize = parseInt(pageSize);
      this.currentPageSize = parseInt(pageSize);
    },
    getPulldownModel(value) {
      if (this.pulldownModel == value.toString()) {
        return;
      }
      this.pulldownModel = value.toString();
      this.fetchDetailsIdeaSources(value);
    },
    handleConfirmSelectData() {
      this.confirmDialog = false;
      this.$emit('submitBasicUnitItem', this.selectedBasicUnit); // set data
      this.$emit('close');
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.searchDialog = true;
    },
    handleDefaultFilterData() {
      // handle set filter data default
      // 0: db customized
      // 1: db item
      // 2: IDEA
      this.filter.clear();
      if (this.isCFP) return; // handle for CFP
      if (!this.objPatternData?.year || !this.objPatternData?.month) return; // handle for supplier
      if (this.isEmissionsView) return; // handle for emissions view
      if (this.pulldownModel != '' && this.pulldownModel !== 'db-customize' && this.pulldownModel !== 'db-item') {
        // select IDEA db -> select all data -> clear all filter
        return;
      }
      const showValues = {};
      const uniqueValuesDefault = [];
      const uniqueValuesNotDefault = [];
      this.listBasicUnitGrid.filter = (item) => {
        if (item?.type_db == 2) {
          if (this.pulldownModel == '' || (this.pulldownModel !== 'db-customize' && this.pulldownModel !== 'db-item')) {
            showValues[item['name_basic']] = true;
          }
          uniqueValuesDefault.push(item['name_basic']);
          return true;
        } else {
          if (this.basicUnitFilterDataDefault[item?.id] == item?.name_basic) {
            uniqueValuesDefault.push(item['name_basic']);
            if (
              this.pulldownModel == ''
              || (item?.type_db == 0 && this.pulldownModel === 'db-customize')
              || (item?.type_db == 1 && this.pulldownModel === 'db-item')
            ) {
              showValues[item['name_basic']] = true;
            }
          } else {
            uniqueValuesNotDefault.push(item['name_basic']);
          }
          return this.basicUnitFilterDataDefault[item?.id] == item?.name_basic;
        }
      }
      const namebasic = this.filter.getColumnFilter('name_basic');
      if (Object.keys(showValues).length == 0) {
        showValues[''] = true;
      }

      namebasic.valueFilter.showValues = showValues;
      namebasic.valueFilter.uniqueValues = [...new Set([...uniqueValuesDefault, ...uniqueValuesNotDefault])];
      namebasic.valueFilter.sortValues = false;
      
      this.listBasicUnitGrid.refresh();
    },
    preparePayloadDbType(value) {
      const dbSourceExists = this.optionsExternalSourcePulldown.some((item) => item.value === value);
      const getPayloadHasDbSourceId = () => {
        if (this.payloadGetListBasicUnit.type_db) delete this.payloadGetListBasicUnit.type_db;
        this.payloadGetListBasicUnit = { ...this.payloadGetListBasicUnit, db_source_id: value, id_ideas: [] };
        return this.payloadGetListBasicUnit;
      };
      const getPayloadHasTypeDb = () => {
        if (this.payloadGetListBasicUnit.db_source_id) delete this.payloadGetListBasicUnit.db_source_id;
        if (value === '') {
          delete this.payloadGetListBasicUnit.type_db;
          return this.payloadGetListBasicUnit;
        }
        value = value === 'db-customize' ? 0 : 1;
        this.payloadGetListBasicUnit = { ...this.payloadGetListBasicUnit, type_db: value };
        return this.payloadGetListBasicUnit;
      };
      return dbSourceExists ? getPayloadHasTypeDb() : getPayloadHasDbSourceId();
    },
    clearFilter() {
      this.filter.clear();
    },
    isFilterActive() {
      const column = ['name_basic', 'value_source', 'unit_source', 'source'];
      let isFilterActive = false,
      isFilterHasSearchText = false;
      for (let i = 0; i < column.length; i++) {
        const columnFilter = this.filter.getColumnFilter(column[i]);
        if (columnFilter.isActive) {
          isFilterActive = true;
          const showValues = columnFilter.valueFilter.showValues;
          if (columnFilter.valueFilter.filterText || (Object.keys(showValues).length > 0 && Object.keys(checkedValues).length < 250)) {
            isFilterHasSearchText = true;
          }
          break;
        }
      }
      return {isFilterActive, isFilterHasSearchText};
    },
    handleTableFilter() {
      this.filter.filterChanged.addHandler((s, e) => {
        const column = s.grid.getColumn(e.col);
        if (['name_basic', 'value_source', 'unit_source', 'source'].includes(column.binding)) {
          const columnFilter = s.getColumnFilter(column.binding);
          const nameBasicFilterText = s.getColumnFilter('name_basic').valueFilter.filterText;
          const valueSourceFilterText = s.getColumnFilter('value_source').valueFilter.filterText;
          const unitSourceFilterText = s.getColumnFilter('unit_source').valueFilter.filterText;
          const sourceFilterText = s.getColumnFilter('source').valueFilter.filterText;
          if (!columnFilter.conditionFilter.isActive) {
            // handle case filter not use condition
            const checkedValues = columnFilter.valueFilter.showValues || {};

            if (!columnFilter.valueFilter.filterText && Object.keys(checkedValues).length == 0) {
              // handle case when clear filter
              
              columnFilter.valueFilter.clear()
              columnFilter.valueFilter.uniqueValues = null;
            } else if (columnFilter.valueFilter.filterText) {
              e.cancel = true;
              const showValues = {}
              s.grid.collectionView.filter = (item) => {
                if (nameBasicFilterText && !item['name_basic'].toLowerCase().includes(nameBasicFilterText)) {
                  return false
                }
                if (valueSourceFilterText && !item['value_source'].toLowerCase().includes(valueSourceFilterText)) {
                  return false
                }
                if (unitSourceFilterText && !item['unit_source'].toLowerCase().includes(unitSourceFilterText)) {
                  return false
                }
                if (sourceFilterText && !item['source'].toLowerCase().includes(sourceFilterText)) {
                  return false
                }
                if (Object.keys(checkedValues).length > 0 && Object.keys(checkedValues).length < 250) {
                  // handle for case has filter text and check options
                  if (!Object.keys(checkedValues).includes(item[column.binding])) {
                    return false;
                  }
                }
                showValues[item[column.binding]] = true
                return true
              }
              columnFilter.valueFilter.showValues = showValues;
            }
          }
        }
      })
    },
    filterAfterSearch() {
      const s = this.filter;
      const nameBasicFilterText = s.getColumnFilter('name_basic').valueFilter.filterText;
      const valueSourceFilterText = s.getColumnFilter('value_source').valueFilter.filterText;
      const unitSourceFilterText = s.getColumnFilter('unit_source').valueFilter.filterText;
      const sourceFilterText = s.getColumnFilter('source').valueFilter.filterText;
      const column = ['name_basic', 'value_source', 'unit_source', 'source'];
      for (let i = 0; i < column.length; i++) {
        const columnFilter = s.getColumnFilter(column[i]);
        if (!columnFilter.isActive) continue;
        if (!columnFilter.conditionFilter.isActive) {
          // handle case filter not use condition
          const checkedValues = columnFilter.valueFilter.showValues || {};
          if (!columnFilter.valueFilter.filterText && Object.keys(checkedValues).length == 0) {
            // handle case when clear filter
            columnFilter.valueFilter.clear()
          } else if (columnFilter.valueFilter.filterText) {
            const showValues = {}
            s.grid.collectionView.filter = (item) => {
              if (nameBasicFilterText && !item['name_basic'].toLowerCase().includes(nameBasicFilterText)) {
                return false
              }
              if (valueSourceFilterText && !item['value_source'].toLowerCase().includes(valueSourceFilterText)) {
                return false
              }
              if (unitSourceFilterText && !item['unit_source'].toLowerCase().includes(unitSourceFilterText)) {
                return false
              }
              if (sourceFilterText && !item['source'].toLowerCase().includes(sourceFilterText)) {
                return false
              }
              if (Object.keys(checkedValues).length > 0 && Object.keys(checkedValues).length < 250) {
                // handle for case has filter text and check options
                if (!Object.keys(checkedValues).includes(item[column[i]])) {
                  return false;
                }
              }
              showValues[item[column[i]]] = true
              return true
            }
            columnFilter.valueFilter.showValues = showValues;
            columnFilter.valueFilter.uniqueValues = [...Object.keys(showValues)];
          }
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin-bottom: 32px;
  .header-form {
    display: flex;
    flex-flow: column;
    gap: 16px;
    &-field {
      display: flex;
      flex-flow: column;
      gap: 8px;
      width: 300px;
      &-label {
        font-size: 14px;
        font-weight: 500;
      }
      &.search-input {
        width: 277px;
      }
      &.condition-pulldown {
        width: 160px;
        &.en {
          width: 200px;
        }
        .condition-search-btn {
          display: flex;
          gap: 16px;
        }
      }
      .custom-pulldown-select {
        width: 108px;
        &.select-pagesize {
          width: 90px;
        }
      }
      span {
        color: $monoBlack;
        font-family: "Source Han Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
      }
      &.page-size-list {
        width: max-content;
      }
      &.clear-filter-btn {
        width: 166px;
        min-width: max-content;
        &.en {
          width: 216px;
        }
      }
      ::v-deep {
        .dropdown-show {
          .dropdown-item {
            width: 100% !important;
          }
        }
      }
    }
  }
  ::v-deep {
    .select-item {
      .v-input__slot {
        background: $monoWhite !important;
      }
    }
    .select-condition {
      .v-select__selection {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
      }
    }
  }
}
.wrap_pagination {
  margin-top: 32px;
}
@include desktop {
  .header-wrapper {
    flex-flow: row;
    margin-top: unset;
    flex-wrap: wrap;
    justify-content: space-between;
    .header-form {
      flex-flow: row;
      align-items: flex-end;
    }
  }
}
.category-table {
  ::v-deep .wrap-table {
    max-height: 352px !important;
    &.wj-flexgrid [wj-part=root] {
      max-height: 352px !important;
    }
  }
}
::v-deep .btn-detail-product {
  .wj-cell-maker {
    color: $goldMid2 !important;
    &:hover {
      color: $monoWhite !important;
    }
  }
}
::v-deep .custom-pulldown-select {
  .v-input__control {
    .v-input__slot {
      background: $monoWhite !important;
    }
  }
}
</style>
<style lang="scss">
  .select-menu {
  &.export-select {
    &.custom-dropdown {
      z-index: 208 !important;
    }
  }
}  
</style>
